<template>
  <div class="programBox">
    <el-row style="width: 100%">
      <el-col :span="24" class="programBackImg">
        <el-image :src="programBackImg" fit="cover" style="width: 100%; height: 100%;cursor: pointer;"></el-image>
      </el-col>
    </el-row>

    <!-- 往期回顾  -->
    <div class="program-1200">
      <div class="titleBox">
        <el-divider>往期回顾</el-divider>
      </div>

      <el-row :gutter="18" style="width: 100%;">
        <el-col :span="8" v-for="(res) in programList">
          <div class="programLi" @click="programDetails(res.id)">
            <div class="programImg"><el-image :src="res.coverImg" fit="fill" style="width: 100%;height: 100%;cursor: pointer;"></el-image></div>
            <div class="programInfo">
              <div class="programName line1">{{res.title}}</div>
              <div class="programName line1">发布时间：{{formatDate(res.publishTime)}}</div>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- 分页 -->
      <div class="paginationBox verticals">
      	<el-pagination background :page-size="queryParams.pageSize" layout="prev, pager, next" @size-change="changeSize" @current-change="changeCurrent" :total="total"></el-pagination>
      </div>

      <!-- 联播媒体平台 -->
      <div class="titleBox">
        <el-divider>联播媒体平台</el-divider>
      </div>

      <div class="platformBox">
        <div class="platformLeft">
          <el-image class="platformImg" :src="platformImg1" fit="fill"></el-image>
        </div>
        <div class="platformRight">
          <div class="platformName">东方卫视</div>
          <div class="platformText">东方卫视，全称上海广播电视台东方卫视频道，昵称番茄台，是上海广播电视台和上海文化广播影视集团有限公司（SMG）的上星频道，开播于2003年10月23日。</div>
          <div class="platformText">
            1998年10月1日，东方卫视的前身上海卫视（全称为上海电视台卫星频道，英文缩写为SBN）开播。
            2003年10月23日，上海卫视更名为东方卫视 。2006年，获得“网络盛典年度新锐卫视”称号 。2007年，
            获得2006中国电视节目榜年度电视频道奖 。2009年9月28日，实现高标清同步播出。2016年，推出社
            交服务类爆笑减压节目《四大名助》 。2017年，星素喜剧挑战竞技秀《笑声传奇》开播 ，2018年精
            准扶贫公益纪实节目《我们在行动》开播  。2021年，获得“2020抖音娱乐年度大赏·抖音年度影响力
            平台”称号。
          </div>
        </div>
      </div>

      <div class="platformBox">
        <div class="platformLeft">
          <el-image class="platformImg" :src="platformImg2" fit="fill"></el-image>
        </div>
        <div class="platformRight">
          <div class="platformName">百视TV</div>
          <div class="platformText">
            《百视TV》是东方明珠新媒体旗下的流媒体平台，拥有海量免费超高清节目内容，涵盖电影、电视剧、综艺、
            少儿、动漫、纪实、游戏、空中课堂、金色学堂等频道，适合全年龄层用户观看。2021年5月25日，东方卫视·百视TV
            夏季赏新大会在上海举行，赏新大会上，上海融东方文化传媒有限公司正式成立，该公司由东方娱乐与东方明珠新媒
            体旗下东方龙新媒体有限公司共同出资成立，通过机制创新提升东方卫视中心团队的内容创制能力和内容价值，同时
            为BesTV+流媒体平台提供优质内容造血能力。
          </div>
        </div>
      </div>

      <div class="platformBox">
        <div class="platformLeft">
          <el-image class="platformImg" :src="platformImg3" fit="fill"></el-image>
        </div>
        <div class="platformRight" style="padding-top: 40px;">
          <div class="platformName">江西卫视</div>
          <div class="platformText">
            江西卫视，全称江西广播电视台卫星频道，昵称辣椒台，隶属于江西广播电视台的综合卫星电视频道；于1970年10月1日正式
            开播，1997年1月1日上星播出。先后打造了《金牌调解》、《传奇故事》、《经典传奇》等系列品牌栏目，2020年推出
            《跨越时空的回信》（第三季）等优秀节目，深耕内容，抓住热点，寻求“小而美”的创新之路。
          </div>
          <div class="platformText">
            截至2020年12月，江西卫视先后获得创新中国电视大奖、中国媒体企划奖金奖、国家广电总局“高清录制技术质量奖”、国家
            广电总局“播出技术质量奖”等荣誉；江西卫视获“年度最具创新影响力省级卫视”。
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
  import { request } from '@/utils/request';
  export default {
    components: {},
    data() {
      return {
        programBackImg:"http://djs.thinkerwork.com/20230824144117412_未标题-1@2x.png",
        platformImg1:"http://djs.thinkerwork.com/20230824150611801_组 4819@2x.png",
        platformImg2:"http://djs.thinkerwork.com/20230824150616455_组 4820@2x.png",
        platformImg3:"http://djs.thinkerwork.com/20230824150641591_组 4821@2x.png",
        //往期列表
        programList:[],
        //总条数
        total:0,
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 12,
        },
      };
    },
    created() {
      this.getList();
    },
    methods: {
      /* 往期列表 */
      getList(){
        request('https://xinke.thinkervc.cn/api/web/activityCms/getPreviousProgramList?pageNum=' + this.queryParams.pageNum + '&pageSize=' +this.queryParams.pageSize,{},{method: 'get'}).then(res => {
          if (res.code === 200) {
           this.programList = res.data.records;
           this.total = res.data.total;
          }
        });
      },
      /* 时间转成年月日 */
      formatDate(dateStr) {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      //分页监听
      changeSize(val){
      	this.queryParams.pageNum = val;
      	this.getList();
      },
      //分页监听
      changeCurrent(val){
      	this.queryParams.pageNum = val;
      	this.getList();
      },
      /* 跳转详情 */
      programDetails(id){
      	this.$router.push({ name: 'ProgramDetails', query: {id:id} });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .programBox{
    width: 100%;
    min-height: 100vh;
  }
  .programBackImg{
    width: 100%;
    height: 360px;
    margin-bottom: 80px;
  }
  .program-1200{
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
  }
  .titleBox{
    padding: 0 350px;
    margin-bottom: 40px;
    overflow: hidden;
  }
  ::v-deep .el-divider__text{
    font-size: 30px;
    font-family: Source Han Sans CN, Source Han Sans CN-Bold;
    font-weight: 700;
    color: #000000;
    letter-spacing: 1.6px;
  }
  ::v-deep .el-divider {
      background-color: #F08307;
  }
  .programLi{
    width: 100%;
    height: 330px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 0px #dbdbdb;
    cursor: pointer;
  }
  .programImg{
    width: 100%;
    height: 220px;
  }
  .programInfo{
    width: 100%;
    padding: 20px 14px;
  }
  .programName{
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    letter-spacing: 0.82px;
    margin-bottom: 15px;
  }
  .line1 {
  	overflow: hidden;
  	text-overflow: ellipsis;
  	white-space: nowrap;
  }
  .paginationBox{
  	width: 100%;
  	height: 80px;
    margin-top: 60px;
  }
  .verticals{
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  }
  .platformBox{
    width: 100%;
    overflow: hidden;
    margin-bottom: 80px;
  }
  .platformLeft{
    float: left;
    width: 324px;
    margin-right: 35px;
  }
  .platformImg{
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: 1px solid #ebebeb;
  }
  .platformRight{
    float: left;
    width: calc(100% - 360px);
  }
  .platformName{
    font-size: 22px;
    font-family: Source Han Sans CN, Source Han Sans CN-Bold;
    font-weight: 700;
    text-align: left;
    color: #000000;
    letter-spacing: 1.2px;
    margin-bottom: 15px;
  }
  .platformText{
    font-size: 14px;
    font-family: Source Han Sans SC, Source Han Sans SC-Regular;
    font-weight: 400;
    text-align: justify;
    color: #000000;
  }
  @media screen and (max-width: 1200px) {}
</style>
